import React from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import { useLocation, useParams } from "react-router-dom";
import withConstants from "@framework/src/libs/withConstants";
import useConstants from "@hooks/useConstants";

import Box from "@material-ui/core/Box";
import { compose } from "recompose";
import withStyles from "@framework/src/libs/withStyles";
import withStores from "@framework/src/libs/withStores";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import HomeIcon from "@components/Global/Custom/Icons/HomeIcon";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";
import PaymentIcon from "@material-ui/icons/Payment";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ErrorIcon from "@material-ui/icons/Error";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import StarsIcon from "@material-ui/icons/Stars";
import RecentActorsOutlinedIcon from "@material-ui/icons/RecentActorsOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import InsertChartOutlinedRoundedIcon from "@material-ui/icons/InsertChartOutlinedRounded";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import AdjustOutlinedIcon from "@material-ui/icons/AdjustOutlined";
import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import MyLocationOutlinedIcon from "@material-ui/icons/MyLocationOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import SpeakerNotesOutlinedIcon from "@material-ui/icons/SpeakerNotesOutlined";
import ClassOutlinedIcon from "@material-ui/icons/ClassOutlined";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import SpeedIcon from "@material-ui/icons/Speed";
import WebOutlinedIcon from "@material-ui/icons/WebOutlined";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DirectionsOutlinedIcon from "@material-ui/icons/DirectionsOutlined";

import RouterComponent from "./RouterComponent";

import { styles, constants, stores } from "./.config";
import { observer } from "mobx-react";

import { adjective as proAdjective } from "@language/provider";

const management = false;

const { LINK_HELP, ENV } = process.env;

const BackgroundNav = compose(
	withStyles(styles),
	withStores(stores),
	withConstants(constants),
	observer
)(({ open }) => {
	const classes = useStyles("Background");
	const { backgroundStore: store, accountStore } = useStores();

	const siteConfigs = useConstants("BackgroundViewModel");

	const { showWhiteboard, saas, referral } = siteConfigs;

	const location = useLocation();
	const params = useParams();

	const topNavItems = [
		{
			url: "/pros",
			title: "Home",
			subRoutes: [],
			siblingRoutes: [],
			icon: <HomeIcon />,
			isManagment: false,
		},
		{
			url: "/pros/calendar",
			title: "Calendar",
			subRoutes: [],
			siblingRoutes: [],
			icon: <DateRangeOutlinedIcon />,
			isManagment: true,
		},

		{
			url: "/pros/leads",
			title: "Direct leads",
			subRoutes: [`/pros/leads/accept/${params.quoteKey}`],
			siblingRoutes: [
				"/pros/leads/matched-leads",
				`/pros/quote/${params.requestKey}/${params.leadKey}`,
			],
			icon: <MyLocationOutlinedIcon />,
			isManagment: false,
			appendEnd: !!store.metrics.unaccepted_direct_leads && (
				<Badge
					badgeContent={store.metrics.unaccepted_direct_leads}
					color="primary"
					overlap="rectangular"
					classes={{
						anchorOriginTopRightRectangular: clsx({
							[classes.navBadge]: open,
							[classes.navBadgeClosed]: !open,
						}),
					}}
				/>
			),
		},
		{
			url: "/pros/leads/matched-leads",
			title: "Opportunities",
			subRoutes: [`/pros/quote/${params.requestKey}/${params.leadKey}`],
			siblingRoutes: ["/pros/leads", `/pros/leads/accept/${params.quoteKey}`],
			icon: <SendOutlinedIcon />,
			isManagment: false,
			appendEnd: store.metrics.opportunities !== 0 && (
				<Badge
					badgeContent={store.metrics.opportunities}
					color="primary"
					overlap="rectangular"
					classes={{
						anchorOriginTopRightRectangular: clsx({
							[classes.navBadge]: open,
							[classes.navBadgeClosed]: !open,
						}),
					}}
				/>
			),
		},
		{
			url: "/pros/contacts",
			title: "Contacts",
			subRoutes: [],
			siblingRoutes: [],
			icon: <RecentActorsOutlinedIcon />,
			isManagment: true,
		},
		{
			url: "/pros/reports",
			title: "Reports",
			subRoutes: [],
			siblingRoutes: [],
			icon: (
				<InsertChartOutlinedRoundedIcon
					style={{
						transform: "scaleX(-1)",
					}}
				/>
			),
			isManagment: true,
		},
		{
			url: "/pros/inbox",
			title: "Messages",
			subRoutes: ["/pros/inbox/chat"],
			siblingRoutes: [],
			icon: <QuestionAnswerOutlinedIcon />,
			isManagment: false,
			appendEnd: !!store.metrics?.unread_inbox_messages && (
				<Badge
					badgeContent={store.metrics?.unread_inbox_messages}
					color="primary"
					overlap="rectangular"
					classes={{
						anchorOriginTopRightRectangular: clsx({
							[classes.navBadge]: open,
							[classes.navBadgeClosed]: !open,
						}),
					}}
				/>
			),
		},

		{
			url: "/pros/services",
			title: "Services",
			subRoutes: [],
			siblingRoutes: [],
			icon: <FormatListBulletedOutlinedIcon />,
			isManagment: false,
			appendEnd: store.provider?.all_preferences_set === false && (
				<Box display={"flex"} position={"absolute"} right={open ? 16 : 4}>
					<ErrorIcon color={"error"} />
				</Box>
			),
		},

		{
			url: "/pros/money",
			title: "Payments",
			subRoutes: [],
			siblingRoutes: [],
			icon: <CreditCardOutlinedIcon />,
			isManagment: true,
		},
		{
			url: "/pros/profile",
			title: "Profile",
			subRoutes: [],
			siblingRoutes: [],
			icon: <AccountBoxOutlinedIcon />,
			isManagment: false,
			appendEnd: store.metrics.onboardingComplete === false && (
				<Box display={"flex"} position={"absolute"} right={open ? 16 : 4}>
					<ErrorIcon color={"error"} />
				</Box>
			),
		},
		{
			url: "/pros/team",
			title: "Team",
			subRoutes: [],
			siblingRoutes: [],
			icon: <PeopleAltOutlinedIcon />,
			isManagment: true,
		},
		{
			url: "/pros/reviews",
			title: "Reviews",
			subRoutes: [],
			siblingRoutes: [],
			icon: <GradeOutlinedIcon />,
			isManagment: false,
			appendEnd: store.metrics.reviews_count === 0 && (
				<Box display={"flex"} position={"absolute"} right={open ? 16 : 4}>
					<ErrorIcon color={"error"} />
				</Box>
			),
		},
		{
			url: "/pros/team",
			title: "Settings",
			subRoutes: [],
			siblingRoutes: [],
			icon: <SettingsOutlinedIcon />,
			isManagment: true,
		},
	];

	const contactsItems = [
		{
			url: "/pros/reviews",
			title: "Reviews",
			subRoutes: [],
			siblingRoutes: [],
			icon: <GradeOutlinedIcon />,
			isManagment: false,
			appendEnd: store.metrics.reviews_count === 0 && (
				<Box display={"flex"} position={"absolute"} right={open ? 16 : 4}>
					<ErrorIcon color={"error"} />
				</Box>
			),
		},
		{
			url: "/pros/xx",
			title: "Auto messages",
			subRoutes: [],
			siblingRoutes: [],
			icon: <QuestionAnswerOutlinedIcon />,
			isManagment: false,
		},
		{
			url: "/pros/xx",
			title: "Notifications",
			subRoutes: [],
			siblingRoutes: [],
			icon: <DnsOutlinedIcon />,
			isManagment: false,
		},
		{
			url: "/pros/tasks",
			title: "Tasks",
			subRoutes: [],
			siblingRoutes: [],
			icon: <AssignmentTurnedInOutlinedIcon />,
			isManagment: true,
		},
	];

	const saasItems = [
		{
			url: `/pros/${store.provider?.onboardingService?.key}/upgrade`,
			title: "Upgrade",
			subRoutes: [],
			siblingRoutes: [],
			icon: <TrendingUpIcon />,
			isManagment: true,
			appendEnd: store.metrics.reviews_count === 0 && (
				<Box display={"flex"} position={"absolute"} right={open ? 16 : 4}>
					<StarsIcon style={{ color: "#635ac7" }} />
				</Box>
			),
		},
	];

	const whiteboardNavItems = [
		{
			url: {
				pathname:
					"https://woowhiteboard.com/online-whiteboard-for-teaching?utm_source=liaison&utm_medium=nav-client&utm_campaign=nav-client",
			},
			title: "Whiteboard",
			subRoutes: [],
			siblingRoutes: [],
			icon: <DashboardOutlinedIcon />,
			isManagment: false,
			target: "_blank",
		},
	];

	const referralItems = [
		{
			url: "/pros/referrals",
			title: `Refer pros. Get $50.`,
			subRoutes: [],
			siblingRoutes: [],
			icon: <AttachMoneyIcon />,
			isManagment: false,
			target: "_blank",
		},
	];

	const mobileNavItems = [
		{
			url: "/pros/settings/payments",
			title: "Billing",
			subRoutes: [],
			siblingRoutes: [],
			icon: <PaymentIcon />,
			isManagment: false,
		},
		{
			url: "/pros/settings",
			title: "Settings",
			subRoutes: [],
			siblingRoutes: [],
			icon: <SettingsIcon />,
			isManagment: false,
		},
		{
			url: { pathname: LINK_HELP },
			title: "Support",
			subRoutes: [],
			siblingRoutes: [],
			icon: <HelpOutlineIcon />,
			isManagment: false,
			target: "_blank",
		},
	];

	const renderNavItems = (items) =>
		items.map((el) => {
			const isSelected = [el.url, ...el.subRoutes].includes(location.pathname);
			const isWithinSPA = [
				el.url,
				...el.subRoutes,
				...el.siblingRoutes,
			].includes(location.pathname);

			return (
				<RouterComponent
					key={el.title}
					isWithinSPA={true}
					url={el.url}
					target={el.target}
				>
					<Tooltip
						enterDelay={400}
						title={el.title}
						arrow
						TransitionComponent={Zoom}
						placement={"right"}
						classes={{
							tooltip: clsx({
								[classes.adminToolTips]: open,
							}),
						}}
					>
						<ListItem
							button
							selected={isSelected}
							classes={{
								root: classes.listPanelItem,
								button: clsx({
									[classes.adminListPanelButton]: open,
								}),
								selected: !open
									? classes.adminListPanelItemActiveClosed
									: isSelected
									? classes.adminListPanelItemActive
									: classes.adminListPanelItem,
							}}
						>
							<ListItemIcon
								className={
									!open
										? classes.adminListItemIconWhite
										: isSelected
										? classes.adminListItemIconActive
										: classes.adminListItemIcon
								}
							>
								{el.icon}
							</ListItemIcon>

							<ListItemText
								className={
									isSelected
										? classes.adminListItemTextActive
										: classes.adminListItemText
								}
								disableTypography
								primary={el.title}
							/>
							{!!el.appendEnd && el.appendEnd}
						</ListItem>
					</Tooltip>
				</RouterComponent>
			);
		});

	return (
		<React.Fragment>
			<>
				<List
					classes={{
						root: clsx({
							[classes.adminList]: open,
						}),
					}}
				>
					{renderNavItems(
						management
							? topNavItems
							: topNavItems.filter((el) => !el.isManagment)
					)}
					{showWhiteboard && <>{renderNavItems(whiteboardNavItems)}</>}
					{saas && <>{renderNavItems(saasItems)}</>}
				</List>

				<Box display={{ xs: "block", sm: "block", md: "none" }}>
					<Divider light />

					<List>
						{renderNavItems(mobileNavItems)}
						{referral && <>{renderNavItems(referralItems)}</>}
					</List>

					<Divider light />

					<List>
						<Tooltip
							enterDelay={400}
							title="Sign out"
							arrow
							TransitionComponent={Zoom}
							placement={"right"}
							classes={{
								tooltip: clsx({
									[classes.adminToolTips]: open,
								}),
							}}
						>
							<ListItem button key={"Sign out"} onClick={accountStore.logout}>
								<ListItemIcon className={classes.adminListItemIcon}>
									<ExitToAppIcon />
								</ListItemIcon>

								<ListItemText
									className={classes.adminListItemText}
									disableTypography
									primary={"Sign out"}
								/>
							</ListItem>
						</Tooltip>
					</List>
				</Box>
				{open && store.displayPromo(siteConfigs) && (
					<Box p={4} mt={6}>
						<ButtonBase
							href={`/pros/${store.provider?.onboardingService?.key}/upgrade`}
						>
							<Paper elevation={4}>
								<Box p={4} whiteSpace={"pre-line"}>
									<Box fontSize={20} fontWeight={700} lineHeight={1.3} mb={2}>
										Want to get 3X more leads?
									</Box>
									<Box fontSize={14} lineHeight={1.5} mb={4}>
										{proAdjective.plural.ucFirst()} who’ve subscribed to our
										premium marketing plan get 3X the number of leads.
									</Box>
									<Button
										color="primary"
										variant="contained"
										disableElevation
										fullWidth
										href={`/pros/${store.provider?.onboardingService?.key}/upgrade`}
									>
										Learn more
									</Button>
								</Box>
							</Paper>
						</ButtonBase>
					</Box>
				)}
			</>
		</React.Fragment>
	);
});

BackgroundNav.propTypes = {
	open: PropTypes.bool,
};

export default BackgroundNav;

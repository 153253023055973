import {observable} from "mobx";

class Button {

    @observable loading = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }


}

export default Button;
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@custom/Button";
import useStores from "@hooks/useStores";
import useError from "@hooks/useError";
import { observer } from "mobx-react";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const AddMoreServicesDialog = observer(() => {
	const theme = useTheme();
	const history = useHistory();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const { addMoreServicesDialogStore: store, backgroundStore } = useStores();
	const [ErrorTemplate, onError] = useError();

	const skipOnClickHandler = () =>
		store
			.stopAskingForMoreServices()
			.then(() => {
				store.onClose();
			})
			.catch(onError);

	const continueOnClickHandler = () =>
		store
			.save()
			.then(() => {
				store.onClose();
				history.push("/pros/services");
			})
			.catch(onError);

	const relationOnChangeHandler = (relation, e) => {
		if (e.target.checked) {
			store.add(relation.consequent._id);
			return;
		}
		store.remove(relation.consequent._id);
	};

	useEffect(() => {
		store.init().catch(onError);
	}, [backgroundStore.provider]);

	if (store.relations.length === 0) return;

	return (
		<Dialog
			scroll={fullScreen ? "paper" : "paper"}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={"xs"}
			open={store.show}
			aria-labelledby="Lead details"
			aria-describedby="Lead details"
		>
			<DialogTitle disableTypography style={{ minHeight: 40 }}>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton aria-label="close" onClick={skipOnClickHandler}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box mb={6}>
					<Box fontWeight={700} fontSize={12} mb={2} color="primary.main">
						PRO TIP
					</Box>
					<Box fontWeight={700} fontSize={22} mb={2}>
						Do you offer any of these services?
					</Box>
					<Box lineHeight={1.5}>
						{`You'll appear in search results and get jobs for every service selected.`}
					</Box>
				</Box>
				<Box pb={6}>
					<FormControl component="fieldset">
						<FormGroup>
							{store.relations.map((relation) => (
								<FormControlLabel
									key={relation._id}
									control={
										<Checkbox
											color={"primary"}
											onChange={(e) => relationOnChangeHandler(relation, e)}
											checked={store.isChecked(relation.consequent._id)}
											id={relation._id}
										/>
									}
									label={
										relation.consequent.name_proper
											? relation.consequent.name_proper.ucFirst()
											: relation.consequent.name.ucFirst()
									}
								/>
							))}
						</FormGroup>
					</FormControl>
				</Box>
			</DialogContent>
			<ErrorTemplate />
			<DialogActions style={{ padding: "24px" }}>
				<Button
					fullWidth
					onClick={skipOnClickHandler}
					style={{ height: 50 }}
					size={"large"}
					variant={"outlined"}
					color="secondary"
					disableElevation
				>
					Skip
				</Button>
				<Button
					fullWidth
					onClick={continueOnClickHandler}
					style={{ height: 50 }}
					size={"large"}
					variant={"contained"}
					color="primary"
					disableElevation
				>
					Add services
				</Button>
			</DialogActions>
		</Dialog>
	);
});

AddMoreServicesDialog.propTypes = {};
export default AddMoreServicesDialog;

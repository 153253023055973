import { compose } from "recompose";
import { stores } from "./.config.js";
import withStores from "@framework/src/libs/withStores";

import _ClaimProfileDialog from "./ClaimProfileDialog.jsx";
import ClaimProfile from "./ClaimProfile.jsx";

export default compose(withStores(stores))(ClaimProfile);

const ClaimProfileDialog = compose(withStores(stores))(_ClaimProfileDialog);

export { ClaimProfileDialog };

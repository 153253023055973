import React, { useEffect } from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";

import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import Looks3Icon from "@material-ui/icons/Looks3";
import Looks4Icon from "@material-ui/icons/Looks4";
import SearchIcon from "@material-ui/icons/Search";
import { adjective as proAdjective } from "@language/provider";
import useStores from "@hooks/useStores";
import useError from "@hooks/useError";

import { observer } from "mobx-react";

const SearchResultsDialog = observer(() => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const { serviceNowSaasDialogStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();

	useEffect(() => {
		if (!store.open) return;
		store.init().catch(onError);
	}, [store.open]);

	return (
		<Dialog
			scroll={fullScreen ? "paper" : "body"}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={"sm"}
			open={store.open}
			onClose={() => store.onClose()}
			aria-labelledby="Lead details"
			aria-describedby="Lead details"
		>
			<DialogTitle disableTypography style={{ minHeight: 50 }}>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton aria-label="close" onClick={() => store.onClose()}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					flexDirection={"column"}
				>
					<SearchIcon color={"primary"} style={{ fontSize: 48 }} />

					<Box mt={4} color={"primary.main"} fontSize={12} fontWeight={700}>
						STARTING TODAY
					</Box>

					<Box
						fontSize={26}
						lineHeight={1.5}
						fontWeight={700}
						mb={10}
						mt={2}
						textAlign="center"
					>
						A new way to get customers for {store.service?.name.ucFirst()}.
					</Box>
				</Box>

				{store.subscription?.features.splice(0, 4).map((feature, i) => (
					<Box
						key={`features-${i}`}
						component={"div"}
						mb={6}
						display={"flex"}
						alignItems={"flex-start"}
					>
						{i === 0 && (
							<LooksOneIcon color="primary" style={{ fontSize: 30 }} />
						)}
						{i === 1 && (
							<LooksTwoIcon color="primary" style={{ fontSize: 30 }} />
						)}
						{i === 2 && <Looks3Icon color="primary" style={{ fontSize: 30 }} />}
						{i === 3 && <Looks4Icon color="primary" style={{ fontSize: 30 }} />}
						<Box ml={3}>
							<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
								{feature}
							</Box>
							{/* <Box color={"grey.700"} lineHeight={1.7}>
								{`As customers fill out their request details, we show qualified ${proAdjective.plural}.`}
							</Box> */}
						</Box>
					</Box>
				))}

				{/* <Box
					component={"div"}
					mb={6}
					display={"flex"}
					alignItems={"flex-start"}
				>
					<LooksTwoIcon color="primary" style={{ fontSize: 30 }} />
					<Box ml={3}>
						<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
							Customers can contact you directly.
						</Box>
						<Box color={"grey.700"} lineHeight={1.7}>
							Customers no longer have to wait for a quote&mdash;they can
							message you directly after seeing your profile. We call this a
							Direct lead, and it’s a big deal.
						</Box>
					</Box>
				</Box>

				<Box
					component={"div"}
					mb={6}
					display={"flex"}
					alignItems={"flex-start"}
				>
					<Looks3Icon color="primary" style={{ fontSize: 30 }} />
					<Box ml={3}>
						<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
							Only pay if you decide to reply.
						</Box>
						<Box color={"grey.700"} lineHeight={1.7}>
							When a customer contacts you, you'll see their job details up
							front. We'll only charge you if you decide to reply or turn on
							auto-pay.
						</Box>
					</Box>
				</Box>
				<Box
					component={"div"}
					mb={6}
					display={"flex"}
					alignItems={"flex-start"}
				>
					<Looks4Icon color="primary" style={{ fontSize: 30 }} />
					<Box ml={3}>
						<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
							If you want more work, you can still send quotes.
						</Box>
						<Box color={"grey.700"} lineHeight={1.7}>
							Browse the Opportunities tab to see more jobs and send quotes.
							Starting today, you'll only pay if the customer responds to you.
							😊
						</Box>
					</Box>
				</Box> */}
			</DialogContent>
			<DialogActions style={{ padding: "24px" }}>
				<Button
					href={`/pros/${store.service?.key}/upgrade`}
					fullWidth
					style={{ height: 50 }}
					size={"large"}
					variant={"contained"}
					color="primary"
					disableElevation
				>
					Upgrade
				</Button>
			</DialogActions>
			<ErrorTemplate />
		</Dialog>
	);
});

SearchResultsDialog.propTypes = {};
export default SearchResultsDialog;

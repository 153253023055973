import React from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";

import Box from "@material-ui/core/Box";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import MyLocationOutlinedIcon from "@material-ui/icons/MyLocationOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import Badge from "@material-ui/core/Badge";

const BackgroundNavTabs = () => {
	const classes = useStyles("Background");

	const [value, setValue] = React.useState(0);

	return (
		<Box display={{ xs: "block", sm: "block", md: "none" }}>
			<BottomNavigation
				value={value}
				onChange={(event, newValue) => {
					setValue(newValue);
				}}
				showLabels
				className={classes.bottomNavigation}
			>
				<BottomNavigationAction
					label="Inbox"
					icon={
						<Badge color="primary" variant="dot">
							<SmsOutlinedIcon />
						</Badge>
					}
					classes={{
						root: classes.bottomNavigationRoot,
						label: classes.bottomNavigationLabel,
						wrapper: classes.bottomNavigationWrapper,
					}}
				/>
				<BottomNavigationAction
					label="Leads"
					icon={<MyLocationOutlinedIcon />}
					classes={{
						root: classes.bottomNavigationRoot,
						label: classes.bottomNavigationLabel,
						wrapper: classes.bottomNavigationWrapper,
					}}
				/>
				<BottomNavigationAction
					label="Bookings"
					icon={<DateRangeOutlinedIcon />}
					classes={{
						root: classes.bottomNavigationRoot,
						label: classes.bottomNavigationLabel,
						wrapper: classes.bottomNavigationWrapper,
					}}
				/>

				<BottomNavigationAction
					label="Sales"
					icon={<LocalAtmOutlinedIcon />}
					classes={{
						root: classes.bottomNavigationRoot,
						label: classes.bottomNavigationLabel,
						wrapper: classes.bottomNavigationWrapper,
					}}
				/>
				<BottomNavigationAction
					label="Contacts"
					icon={<AccountCircleOutlinedIcon />}
					classes={{
						root: classes.bottomNavigationRoot,
						label: classes.bottomNavigationLabel,
						wrapper: classes.bottomNavigationWrapper,
					}}
				/>
			</BottomNavigation>
		</Box>
	);
};

BackgroundNavTabs.propTypes = {};
export default BackgroundNavTabs;

import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { compose } from "recompose";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
// import Container from "@material-ui/core/Container";

import useStyles from "@hooks/useStyles";
import useError from "@hooks/useError";

import withConstants from "@framework/src/libs/withConstants";
import withStyles from "@framework/src/libs/withStyles";
import withStores from "@libs/framework/src/libs/withStores";

import BackgroundNav from "@components/Dashboard/Pro/Shared/Background/BackgroundNav";
import Header from "@components/Dashboard/Pro/Shared/Header";
import PropTypes from "prop-types";

import { styles, constants, stores } from "./.config";
import useStores from "@libs/framework/src/hooks/useStores";
import DollarsCreditsDialog from "./Modals/DollarsCreditsDialog";
import SearchResultsDialog from "./Modals/SearchResultsDialog";
import ServiceNowSaasDialog from "./Modals/ServiceNowSaasDialog";
import AddMoreServicesDialog from "./Modals/AddMoreServicesDialog";

import { ClaimProfileDialog } from "../ClaimProfile";
import { getParameterByName } from "@utils/Browser";
import useSuccess from "@hooks/useSuccess";

import BackgroundNavTabs from "./BackgroundNavTabs";
import BackgroundNavManagement from "./BackgroundNavManagement";
import PlatformOnboardingDialog from "./Modals/PlatformOnboardingDialog";
import AiEmployeeDialog from "@pro/Shared/Background/Modals/AiEmployeeDialog";

const { LINK_HELP, ENV } = process.env;

const Background = compose(
	withStyles(styles),
	withConstants(constants),
	withStores(stores, { setGlobal: true }),
	observer,
)(({
	children,
	drawerHidden,
	toggleNotification,
	preventClaimProfileDialog,
	tabs,
}) => {
	const {
		backgroundStore: store,
		dollarsCreditsDialogStore,
		addMoreServicesDialogStore,
		claimProfileDialogStore,
		serviceNowSaasDialogStore,
	} = useStores();

	const [ErrorTemplate, onError] = useError();
	const [SuccessTemplate, onSuccess] = useSuccess({
		_id: "BackgroundSuccess",
	});

	const location = useLocation();
	const handleToggleDrawer = () => {
		let drawerState = store.drawerState;
		if (localStorage.getItem("drawerState") && !drawerHidden)
			drawerState = JSON.parse(localStorage.getItem("drawerState"));
		store.drawerState = !drawerState;
		if (!drawerHidden)
			localStorage.setItem("drawerState", JSON.stringify(store.drawerState));
	};

	const classes = useStyles("Background");
	const anchor = store.anchor;

	const handleToggleNav = (anchor) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		store.navState[anchor] = !store.navState[anchor];
	};

	useEffect(() => {
		const storageDrawerState = JSON.parse(localStorage.getItem("drawerState"));

		let defaultState;
		if (!drawerHidden) {
			if (storageDrawerState !== null) defaultState = storageDrawerState;
			else {
				localStorage.setItem("drawerState", true);
				defaultState = true;
			}
		} else {
			defaultState = false;
		}
		store.drawerState = defaultState;
	}, []);

	useEffect(() => {
		const upgraded = getParameterByName("upgrade") === "success";
		if (upgraded) {
			onSuccess({ message: "Subscription Upgraded" });
		}
	}, []);

	useEffect(() => {
		store.init().catch(onError);
	}, [location.pathname]);

	useEffect(() => {
		if (preventClaimProfileDialog) {
			claimProfileDialogStore.show = false;
			return;
		}
		if (!store.provider) return;
		if (store.provider.track?.source !== "savvy") return;
		if (store.provider.claimed) return;
		claimProfileDialogStore.show = true;
	}, [store.provider]);

	useEffect(() => {
		if (!store.provider) return;
		if (
			!store.provider.claimed &&
			store.provider.track?.source === "savvy" &&
			!preventClaimProfileDialog
		)
			return;
		if (
			store.provider.legacy &&
			!store.provider.legacy_onboarded &&
			!store.provider.saas_notify_service_configuration
		) {
			dollarsCreditsDialogStore.toggle();
		}
		if (store.provider.saas_notify_service_configuration) {
			serviceNowSaasDialogStore.toggle();
		}
	}, [store.provider]);

	useEffect(() => {
		if (!store.provider) return;
		if (store.provider.legacy && !store.provider.legacy_onboarded) return;
		if (store.provider.saas_notify_service_configuration) return;
		if (
			!store.provider.claimed &&
			store.provider.track?.source === "savvy" &&
			!preventClaimProfileDialog
		)
			return;
		if (!store.provider.asked_for_more_services)
			addMoreServicesDialogStore.open();
	}, [store.provider, addMoreServicesDialogStore?.relations]);

	return (
		<React.Fragment>
			<div className={classes.root}>
				<Header
					toggleNotification={toggleNotification}
					toggleDrawer={handleToggleDrawer}
					toggleNav={handleToggleNav(anchor)}
				/>

				<Box display={{ xs: "block", sm: "block", md: "none" }}>
					<Drawer
						className={classes.drawer}
						classes={{ paper: classes.drawer }}
						anchor={anchor}
						open={store.navState[anchor]}
						onClose={handleToggleNav(anchor)}
					>
						{(ENV === "stage-OFF" || ENV === "production") && (
							<BackgroundNav open={store.drawerState} />
						)}
						{(ENV === "stage" || ENV === "development") && (
							<BackgroundNavManagement open={store.drawerState} />
						)}
					</Drawer>
				</Box>
				<Box display={{ xs: "none", sm: "none", md: "block" }}>
					<Drawer
						variant="permanent"
						className={clsx(classes.drawer, {
							[classes.drawerOpen]: store.drawerState,
							[classes.drawerClose]: !store.drawerState,
							[classes.drawerHidden]: drawerHidden && !store.drawerState,
						})}
						classes={{
							paper: clsx({
								[classes.drawerOpen]: store.drawerState,
								[classes.drawerClose]: !store.drawerState,
								[classes.drawerHidden]: drawerHidden && !store.drawerState,
							}),
						}}
					>
						<Box
							display={"flex"}
							flexDirection={"column"}
							//borderLeft={"1px #d9d9d9 solid"}
							//boxShadow={"0 4px 8px rgba(0,0,0,.1), 0 2px 16px rgba(0,0,0,.1)"}
							width={"100%"}
							height={"100%"}
							//bgcolor={"#FFFFFF"}
						>
							<div className={classes.toolbarSpacing} />

							<Divider light />

							{(ENV === "stage-OFF" || ENV === "production") && (
								<BackgroundNav open={store.drawerState} />
							)}
							{(ENV === "stage" || ENV === "development") && (
								<BackgroundNavManagement open={store.drawerState} />
							)}
						</Box>
					</Drawer>
				</Box>

				<Box
					component={"main"}
					position={"relative"}
					className={clsx({
						[classes.content]: store.drawerState,
						[classes.contentClose]: !store.drawerState,
					})}
				>
					<div className={classes.toolbarSpacing} />
					{children}

					{(ENV === "stage" || ENV === "development") && (
						<>{tabs && <BackgroundNavTabs />}</>
					)}
				</Box>
				<ErrorTemplate />
				<SuccessTemplate />
			</div>
			{/* <AiEmployeeDialog /> */}
			{store.provider?.legacy && <DollarsCreditsDialog />}
			{store.provider?.legacy && <SearchResultsDialog />}
			{!store.provider?.asked_for_more_services && <AddMoreServicesDialog />}
			{!preventClaimProfileDialog && <ClaimProfileDialog />}
			{store.provider?.saas_notify_service_configuration && (
				<ServiceNowSaasDialog />
			)}
			{/* <PlatformOnboardingDialog /> */}
		</React.Fragment>
	);
});

Background.defaultProps = {
	drawerHidden: false,
	tabs: true,
};

Background.propTypes = {
	drawerHidden: PropTypes.bool,
	tabs: PropTypes.bool,
};

export default Background;

import React, { useEffect } from "react";

import Box from "@material-ui/core/Box";

import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";

import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import { adjective as proAdjective } from "@language/provider";
import { adjective as clientAdjective } from "@language/client";
import useStores from "@hooks/useStores";
import useError from "@hooks/useError";
import useSuccess from "@hooks/useSuccess";
import PersonPinCircleOutlinedIcon from "@material-ui/icons/PersonPinCircleOutlined";

import { observer } from "mobx-react";

const { BRAND_NAME } = process.env;

const ClaimProfile = observer(({ onSuccess }) => {
	const { claimProfileStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();
	if (!onSuccess) {
		var [SuccessTemplate, onSuccess] = useSuccess({
			_id: "claimProfileSuccess",
		});
	}

	useEffect(() => {
		store.init().catch(onError);
	}, []);

	const continueOnClickHandler = () => {
		store
			.claimProfile({ onSuccess, message: "Your information is confirmed." })
			.then()
			.catch(onError);
	};

	return (
		<React.Fragment>
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				flexDirection={"column"}
			>
				<PersonPinCircleOutlinedIcon
					color={"primary"}
					style={{ fontSize: 48 }}
				/>

				{/* <Box component={"div"} maxWidth={120} m="0 auto">
						<Box
							component={"img"}
							width={"100%"}
							alt={"Settings"}
							src="//static.homeguide.com/assets/images/desktop/pro-funnel-settings-3d.png"
							loading={"lazy"}
						/>
					</Box> */}

				{/* <Box mt={4} color={"primary.main"} fontSize={12} fontWeight={700}>
					{store.provider?.company_name?.toUpperCase()}
				</Box> */}

				<Box
					fontSize={28}
					lineHeight={1.4}
					fontWeight={700}
					mb={10}
					mt={4}
					textAlign="center"
					maxWidth={420}
				>
					{`Get new customers with ${BRAND_NAME}`}
				</Box>
			</Box>

			<Box component={"div"} mb={6} display={"flex"} alignItems={"flex-start"}>
				<CheckOutlinedIcon color="primary" style={{ fontSize: 30 }} />
				<Box ml={3}>
					<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
						Used by 14+ million {clientAdjective.plural} to find{" "}
						{proAdjective.plural}
					</Box>
					{/* <Box color={"grey.700"} lineHeight={1.7} fontSize={14}>
						{`Running a business is hard, but getting new customers shouldn't be. Soon, you'll gain access to local customers looking to hire ${proAdjective.plural}.`}
					</Box> */}
				</Box>
			</Box>

			<Box component={"div"} mb={6} display={"flex"} alignItems={"flex-start"}>
				<CheckOutlinedIcon color="primary" style={{ fontSize: 30 }} />
				<Box ml={3}>
					<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
						Trusted by 30,000+ {proAdjective.plural} like you
					</Box>
					{/* <Box color={"grey.700"} lineHeight={1.7} fontSize={14}>
						{`Grow your business like the best. Your profile is free and there's no commission fees. If you want even more customers, you can optionally choose to pay.`}
					</Box> */}
				</Box>
			</Box>

			<Box component={"div"} mb={6} display={"flex"} alignItems={"flex-start"}>
				{/* <ArrowForwardOutlinedIcon style={{ fontSize: 30, color: "#ff5459" }} /> */}
				<CheckOutlinedIcon color="primary" style={{ fontSize: 30 }} />
				<Box ml={3}>
					<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
						{/* <Box
							component={"span"}
							fontWeight={700}
							color={"#ff5459"}
							display={{ xs: "none", sm: "inline-block" }}
						>
							Pending:
						</Box>{" "} */}
						{`Get a free profile on ${BRAND_NAME}`}
					</Box>
					{/* <Box color={"grey.700"} lineHeight={1.7} fontSize={14}>
						{`Before you can respond to new customers, we need to verify some info on the next step. It'll only take a minute.`}
					</Box> */}
				</Box>
			</Box>

			<Box
				//borderTop={"1px solid rgba(0, 0, 0, 0.12)"}
				py={6}
				maxWidth={{ xs: "100%", sm: 260 }}
				m={"0 auto"}
			>
				<Button
					onClick={continueOnClickHandler}
					fullWidth
					style={{ height: 50 }}
					size={"large"}
					variant={"contained"}
					color="primary"
					disableElevation
				>
					Continue
				</Button>
			</Box>

			<Box
				fontSize={12}
				color={"grey.600"}
				lineHeight={1.5}
				textAlign={"center"}
			>
				By clicking "Continue", you confirm that you are an authorized
				representative of {store.provider?.company_name?.ucFirst()} and you
				agree to our{" "}
				<Link href="/terms-of-service" target={"_blank"}>
					terms of service
				</Link>{" "}
				and{" "}
				<Link href="/privacy-policy" target={"_blank"}>
					privacy policy
				</Link>
				.
			</Box>

			<ErrorTemplate />
			{SuccessTemplate && <SuccessTemplate />}
		</React.Fragment>
	);
});

ClaimProfile.propTypes = {};
export default ClaimProfile;

import React from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";

import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import useStores from "@hooks/useStores";
import { observer } from "mobx-react";

const DollarsCreditsDialog = observer(() => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const {
		dollarsCreditsDialogStore: store,
		headerStore,
		searchResultsDialogStore,
	} = useStores();

	const continueOnClickHandler = () => {
		store.onClose();
		searchResultsDialogStore.toggle();
	};

	return (
		<Dialog
			scroll={fullScreen ? "paper" : "body"}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={"sm"}
			open={store.show}
			// onClose={store.onClose}
			aria-labelledby="Lead details"
			aria-describedby="Lead details"
		>
			<DialogTitle disableTypography style={{ minHeight: 40 }}>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton aria-label="close" onClick={continueOnClickHandler}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					flexDirection={"column"}
				>
					<LocalAtmOutlinedIcon color={"primary"} style={{ fontSize: 48 }} />

					<Box mt={4} color={"primary.main"} fontSize={12} fontWeight={700}>
						STARTING TODAY
					</Box>

					<Box
						fontSize={26}
						lineHeight={1.5}
						fontWeight={700}
						mb={5}
						mt={2}
						textAlign="center"
					>
						Pay with dollars, not credits.
					</Box>
				</Box>

				<Box lineHeight={1.7} color={"grey.600"} mb={8} textAlign="center">
					We made it easier for you to see exactly how much you're paying.
				</Box>

				<Box
					component={"div"}
					mb={6}
					display={"flex"}
					alignItems={"flex-start"}
				>
					<LocalAtmOutlinedIcon style={{ fontSize: 30 }} />
					<Box ml={3}>
						<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
							You'll see prices in dollars instead of credits.
						</Box>
						<Box color={"grey.700"} lineHeight={1.7}>
							So now prices are easier to understand.
						</Box>
					</Box>
				</Box>
				{/* 
				<Box
					component={"div"}
					mb={6}
					display={"flex"}
					alignItems={"flex-start"}
				>
					<MoneyOffIcon style={{ fontSize: 30 }} />
					<Box ml={3}>
						<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
							Base lead prices are not changing.
						</Box>
						<Box color={"grey.700"} lineHeight={1.7}>
							We're just showing prices in dollars instead of credits.
						</Box>
					</Box>
				</Box> */}

				<Box
					component={"div"}
					mb={6}
					display={"flex"}
					alignItems={"flex-start"}
				>
					<AccountBalanceWalletOutlinedIcon style={{ fontSize: 30 }} />
					<Box ml={3}>
						<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
							You have a balance of {headerStore.provider?.formatted_balance}.
						</Box>
						<Box color={"grey.700"} lineHeight={1.7}>
							You can check your balance at any time in your payment settings.
						</Box>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions style={{ padding: "24px" }}>
				<Button
					fullWidth
					onClick={continueOnClickHandler}
					style={{ height: 50 }}
					size={"large"}
					variant={"contained"}
					color="primary"
					disableElevation
				>
					Continue
				</Button>
			</DialogActions>
		</Dialog>
	);
});

DollarsCreditsDialog.propTypes = {};
export default DollarsCreditsDialog;

import React from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";

import ClaimProfile from "./ClaimProfile";
import { observer } from "mobx-react";
import useStores from "@hooks/useStores";
import useSuccess from "@hooks/useSuccess";

const ClaimProfileDialog = observer(() => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const { claimProfileDialogStore: store } = useStores();
	const [SuccessTemplate, onSuccess] = useSuccess({
		_id: "claimProfileDialogSuccess",
	});

	return (
		<React.Fragment>
			<Dialog
				scroll={fullScreen ? "paper" : "body"}
				fullScreen={fullScreen}
				fullWidth={true}
				maxWidth={"sm"}
				open={store.show}
			>
				<DialogContent>
					<ClaimProfile onSuccess={onSuccess} />
				</DialogContent>
			</Dialog>
			<SuccessTemplate />
		</React.Fragment>
	);
});

ClaimProfileDialog.propTypes = {};
export default ClaimProfileDialog;

import InstanceFactoryStoreManager from "@src/stores/InstanceFactoryStoreManager";
import required from "@libs/required";

import Button from "./Button";

class ButtonStore extends InstanceFactoryStoreManager {
	constructor(rootStore) {
		super(rootStore);
	}

	init(id = required`id`) {
		super.init(id, Button);
	}

	findInstanceById(_id = required`_id`) {
		return super.findInstanceById(_id);
	}

	isLoading(_id = required`_id`) {
		const instance = this.findInstanceById(_id);
		if (!instance) return false;
		return instance.loading;
	}
}

export default ButtonStore;

import React, { forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { observer } from "mobx-react";
import shortid from "shortid";

import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

import withStyles from "@framework/src/libs/withStyles";
import withStores from "@framework/src/libs/withStores";

import Box from "@material-ui/core/Box";
import MaterialButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { styles, stores } from "./.config";
import { isPromise } from "@utils/Browser";

const Button = compose(
	withStores(stores),
	withStyles(styles),
	observer,
	forwardRef
)(
	(
		{
			id,
			label,
			loadingLabel,
			onClick,
			containerClassName,
			boxPosition,
			children,
			...props
		},
		ref
	) => {
		const classes = useStyles("Button");
		const [_id] = useState(id || shortid.generate());
		const { buttonStore: store } = useStores();

		const instance = store.findInstanceById(_id);

		const onClickHandler = (e) => {
			const response = onClick(e, instance);
			if (isPromise(response)) {
				instance.loading = true;
				return response.then(() => {
					instance.loading = false;
				});
			}
			return response;
		};

		useEffect(() => {
			store.init(_id);
		}, []);

		return (
			<Box position={boxPosition} className={containerClassName}>
				<MaterialButton
					id={id}
					ref={ref}
					disabled={store.isLoading(_id)}
					onClick={onClickHandler}
					{...props}
				>
					{store.isLoading(_id)
						? loadingLabel || label || children
						: label || children}
				</MaterialButton>
				{store.isLoading(_id) && (
					<CircularProgress size={24} className={classes.buttonProgress} />
				)}
			</Box>
		);
	}
);

Button.defaultProps = {
	color: "primary",
	onClick: () => {},
	boxPosition: "relative",
};

Button.propTypes = {
	color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
	variant: PropTypes.oneOf(["contained", "outlined", "text"]),
	className: PropTypes.string,
	label: PropTypes.string,
	loadingLabel: PropTypes.string,
	onClick: PropTypes.func,
	boxPosition: PropTypes.string,
};

export default Button;

import React from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";

import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import Looks3Icon from "@material-ui/icons/Looks3";
import Looks4Icon from "@material-ui/icons/Looks4";
import SearchIcon from "@material-ui/icons/Search";
import { adjective as proAdjective } from "@language/provider";
import useStores from "@hooks/useStores";
import useError from "@hooks/useError";

import { observer } from "mobx-react";
import RobotIcon from "@components/Global/Custom/Icons/RobotIcon";

const AiEmployeeDialog = observer(() => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const {
		// AiEmployeeDialogStore: store,
		// backgroundStore,
		// addMoreServicesDialogStore,
	} = useStores();
	const [ErrorTemplate, onError] = useError();

	const continueOnClickHandler = () => {
		store
			.legacyOnboardingCompleted()
			.then(() => {
				store.onClose();
			})
			.catch(onError);
	};

	return (
		<Dialog
			scroll={fullScreen ? "paper" : "body"}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={"sm"}
			//open={store.open}
			open={false}
			//onClose={store.onClose}
			aria-labelledby="Lead details"
			aria-describedby="Lead details"
		>
			<DialogTitle disableTypography style={{ minHeight: 50 }}>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton aria-label="close" onClick={continueOnClickHandler}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					flexDirection={"column"}
				>
					<RobotIcon style={{ fontSize: 48, color: "#006afa" }} />

					{/* <Box mt={4} color={"primary.main"} fontSize={12} fontWeight={700}>
						NEW
					</Box> */}

					<Box
						fontSize={26}
						lineHeight={1.5}
						fontWeight={700}
						mb={6}
						mt={3}
						textAlign="center"
					>
						Meet Max, your new AI employee
					</Box>
				</Box>

				<Box lineHeight={1.5} mb={8} textAlign={"center"}>
					Max sells, schedules, and communicates with customers, while still
					sounding human. If you have targeting on, Max will auto-respond to the
					lead (one-time) instantly to increase your sales and bookings.
				</Box>

				<Box
					component={"div"}
					mb={6}
					display={"flex"}
					alignItems={"flex-start"}
				>
					<LooksOneIcon color="primary" style={{ fontSize: 30 }} />
					<Box ml={3}>
						<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
							Responding within one minute leads to 391% more conversions
						</Box>
						<Box color={"grey.700"} lineHeight={1.7}>
							{`Max now does this for you.`}
						</Box>
					</Box>
				</Box>

				<Box
					component={"div"}
					mb={6}
					display={"flex"}
					alignItems={"flex-start"}
				>
					<LooksTwoIcon color="primary" style={{ fontSize: 30 }} />
					<Box ml={3}>
						<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
							80% of deals go to the business that reached out first
						</Box>
						<Box color={"grey.700"} lineHeight={1.7}>
							{`If you’re not reaching out to your leads with SPEED, then you’re losing revenue!`}
						</Box>
					</Box>
				</Box>

				<Box
					component={"div"}
					mb={6}
					display={"flex"}
					alignItems={"flex-start"}
				>
					<Looks3Icon color="primary" style={{ fontSize: 30 }} />
					<Box ml={3}>
						<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
							Free to use. Turn Max on or off at any time
						</Box>
						<Box color={"grey.700"} lineHeight={1.7}>
							On any chat page, click the menu to toggle off Max.
						</Box>
					</Box>
				</Box>
				{/* <Box
					component={"div"}
					mb={6}
					display={"flex"}
					alignItems={"flex-start"}
				>
					<Looks4Icon color="primary" style={{ fontSize: 30 }} />
					<Box ml={3}>
						<Box fontWeight={600} mb={1} fontSize={18} lineHeight={1.5}>
							If you want more work, you can still send quotes.
						</Box>
						<Box color={"grey.700"} lineHeight={1.7}>
							Browse the Opportunities tab to see more jobs and send quotes.
							Starting today, you'll only pay if the customer responds to you.
							😊
						</Box>
					</Box>
				</Box> */}
			</DialogContent>
			<DialogActions style={{ padding: "24px" }}>
				<Box width={"100%"}>
					<Button
						onClick={continueOnClickHandler}
						fullWidth
						style={{ height: 50 }}
						size={"large"}
						variant={"contained"}
						color="primary"
						disableElevation
					>
						Continue
						{/* Turn on Max */}
					</Button>
					<Box my={4}>
						<Button
							//onClick={continueOnClickHandler}
							fullWidth
							//style={{ height: 50 }}
							//size={"large"}
							//variant={"contained"}
							color="primary"
							disableElevation
						>
							Turn off Max
							{/* I'm not interested */}
						</Button>
					</Box>
				</Box>
			</DialogActions>
			<ErrorTemplate />
		</Dialog>
	);
});

AiEmployeeDialog.propTypes = {};
export default AiEmployeeDialog;
